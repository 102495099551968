<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2024-03-13 09:06:34
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-03-13 12:08:38
-->
<template>
  <router-view></router-view>
</template>

