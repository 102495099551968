/*
 * @Description: 获取个人资料
 * @Author: 琢磨先生
 * @Date: 2022-11-25 10:26:43
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-03-13 16:08:34
 */

import http from "./axios_init";
export default {
  /**
   * 获取个人资料
   * @returns
   */
  get_center() {
    return http.get("admin/v1/center");
  },

  /**
   * 获取菜单数据
   */
  get_menus() {
    return http.get("admin/v1/center/vue_route");
  },
  /**
   * 获取接口数据
   */
  get_apis() {
    return http.get("admin/v1/center/apis");
  },


};
